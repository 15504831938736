// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700;900&display=swap');




@font-face {
    font-family: 'GmarketSansMedium';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GmarketSansLight';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansLight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GmarketSansBold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

body{
    background: #fff;
    font-family: 'Noto Sans KR', sans-serif;
}

a{
    color: #222;
}

a:hover{
    text-decoration: none;
}

.container{
    padding: 0;
    /*margin-bottom: 80px;*/
    max-width: none;
}

.card{
    border: none;
    border-bottom: 4px solid #f5f5f5;
}

.card-header{
    font-weight: 700;
    background-color: #fff;
    border-bottom: none;
    font-size: 1rem;
    padding-bottom: 0;
}

.card-body{
    padding: 0.4rem 1.25rem 0.8rem;
}


.s-point{
    color: #ec8858;
}

.form-control{
    padding: 0.375rem 0.8rem !important;
    border: 1px solid #e6e6e6;
    height: calc(1.6em + 0.98rem + 2px);
}

.form-control:disabled, .form-control[readonly]{
    background-color: #fff;
}

label{
    margin-bottom: 0.2rem !important;
}

.s-nt p{
    font-size: 0.7rem;
    color: #666;
    margin-bottom: 5px;
}

ul, li{
    list-style: none;
    display: list-item;
    text-align: -webkit-match-parent;
}

.l-btn{
    margin: 20px 0;
    background: #ffae30;
    color: #fff;
    border: #ffae30;
}

.info_teble{
    font-size: 0.75rem;
    padding: 20px;
    background: #f8f9fa;
    margin: 15px 0;
}

.pay-table > ul,
.pay-table > ul li{
    padding-left: 0;
}

.p-info{
    padding: 15px 20px 5px 20px;
    background: #f3f3f3;
    margin: 10px 10px 15px 10px;
}

.p-info select,
.p-info p{
    font-size: 0.8rem;
}

.border-tb{
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
}

.m-btn-wrap li{
    float: left;
    padding: 0;
    margin: 3px 5px 0 0;
}

.m-btn-wrap li:nth-child(1){
    margin-left: 0;
}

.m-btn {
    color: #fff;
    background: #c3c3c3;
    font-size: 0.7rem;
    padding: 3px 7px;
    border-radius: 10px;
    letter-spacing: -0.5px;
}


.g-btn {
    border: 1px solid #e6e6e6;
    border-radius: 0.25rem;
    font-size: 0.85rem;
    height: 44px !important;
    line-height: 30px;
}

.g-btn:not(:disabled):not(.disabled):active,
.g-btn:not(:disabled):not(.disabled).active,
.show > .g-btn.dropdown-toggle {
    color: #fff;
    background-color: #d6382c;
    border: 1px solid #d6382c;
    border-radius: .25rem;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
    border-radius: .25rem;
}


.p-r{
    padding-right: 0 !important;
}

.form-control::placeholder,
input::placeholder{
    color: #bfbfbf;
}

.n-icon{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(236, 0, 64)' d='M8.999,12.999 L10.999,12.999 L10.999,14.999 L8.999,14.999 L8.999,12.999 ZM8.999,4.999 L10.999,4.999 L10.999,10.999 L8.999,10.999 L8.999,4.999 ZM9.989,0.0 C4.469,0.0 0.0,4.479 0.0,9.999 C0.0,15.519 4.469,19.999 9.989,19.999 C15.519,19.999 19.999,15.519 19.999,9.999 C19.999,4.479 15.519,0.0 9.989,0.0 ZM9.999,17.999 C5.579,17.999 1.999,14.419 1.999,9.999 C1.999,5.579 5.579,1.999 9.999,1.999 C14.419,1.999 17.999,5.579 17.999,9.999 C17.999,14.419 14.419,17.999 9.999,17.999 Z'/%3E%3C/svg%3E");
    background-size: 100%;
    width: 10px;
    height: 10px;
    display: inline-flex;
    margin-right: 2px;
}

.c666{
    color: #666;
}

.n-text{
    display: inline-flex;
    width: 94%;
}

.form-select {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .55rem center;
    background-size: 14px 10px;
    border: 1px solid #e6e6e6;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: calc(1.6em + 0.98rem + 2px);
}


.suggestion_box,
.suggestion_box02{
    display: none;
    position: absolute;
    width: 92%;
    border-top: 0;
    background-color: #fff;
    z-index: 12;
    overflow-y: auto;
    min-height: 10vw;
    max-height: 140px;
    border: 1px solid #222;
    left: 15px;
    border-radius: 0.25rem;
    padding: 0;
}

.suggestion_box li:last-child,
.suggestion_box02 li:last-child{
    border-bottom: 0;
}

.suggestion_box li,
.suggestion_box02 li{
    cursor: pointer;
    padding: 8px;
    border-bottom: 1px solid #e6e6e6;
    padding-left: 20px;
    color: #333;
    font-size: 0.88rem;
}

.btn{
    height: calc(1.6em + 0.98rem + 2px);
}

.btn-black-info{
    color: #222;
    border-color: #222;
}

.navbar{
    padding: 0.5rem 1rem 0;
}

.navbar-brand{
    font-size: 1.25rem;
    font-family: 'GmarketSansBold';
    color: #222;
    padding-top: 15px;
    padding-bottom: 0;
}


.navbar-brand > span {
    font-family: 'GmarketSansMedium';
    padding-left: 0.2rem;
}

.shop-area{
    float: right;
    position: relative;
    float: left;
    /*text-align: center;
    width: 46px;*/
}


.shop-icon{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='48px' height='48px'%3E%3Cpath fill-rule='evenodd' fill='rgb(255, 255, 255)' d='M-0.000,-0.000 L48.000,-0.000 L48.000,48.000 L-0.000,48.000 L-0.000,-0.000 Z'/%3E%3Cpath fill-rule='evenodd' fill='rgb(214, 56, 44)' d='M39.000,43.000 L9.000,43.000 C6.791,43.000 5.000,41.211 5.000,39.003 L5.000,38.884 L5.000,35.007 L5.000,18.371 C5.000,17.075 5.675,15.862 6.800,15.080 L21.300,5.824 C22.897,4.733 25.102,4.733 26.700,5.824 L41.200,15.080 C42.325,15.862 43.000,17.075 43.000,18.371 L43.000,35.007 L43.000,38.884 L43.000,39.003 C43.000,41.211 41.209,43.000 39.000,43.000 Z'/%3E%3Cpath fill-rule='evenodd' fill='rgb(255, 255, 255)' d='M24.000,31.000 C25.104,31.000 26.000,31.895 26.000,33.000 L26.000,43.000 L22.000,43.000 L22.000,33.000 C22.000,31.895 22.895,31.000 24.000,31.000 Z'/%3E%3C/svg%3E");
    background-size: 24px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
    float: left;
    margin-right: 5px;
}

.shop-text{
    font-size: 0.6rem;
    float: left;
    /*margin-left: 8px;
    margin-top: -5px;*/
    color: #d6382c;
    line-height: 13px;
    font-weight: bold;

}

.payment-table{
    background: #f7f7f7;
    margin-top: 10px;
    padding: 15px;
    display: inline-block;
    width: 100%;
}


input[type='radio'],
input[type='radio']:checked {
    appearance: none;
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 100%;
    margin-right: 0.1rem;
    vertical-align: middle;
}

input[type='radio'] {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    vertical-align: middle;
}

input[type='radio']:checked {
    background-color: #d6382c;
    border: 1px solid #e6e6e6;
    vertical-align: middle;
}


.payment-table ul li{
    float: left;
    padding: 0.3rem 0.3rem;
}

.mb-05{
    margin-bottom: 0.5rem;
}

.bottom-btn-area {
    position: relative;
    background: #fff;
    height: 70px;
    width: 100%;
    text-align: center;
}

.bottom-btn-area > span{
    display: inline-block;
    text-align: left;
    padding-left: 0;
    position: absolute;
    width: 28vw;
    left: 25px;
    top: 15px;
}

.bottom-btn{
    color: #fff;
    background: #d6382c;
    display: inline-block;
    border: none;
    position: relative;
    text-align: center;
    width: 90vw;
    height: 50px;
    font-size: 1rem;
    border-radius: 0.25rem;
    margin-top: 10px;
}

.payment-end{
    text-align: center;
    height: 82vh;
    display: table-cell;
    vertical-align: middle;
    width: 100vw;
    position: relative;
}


.payment-end > p{
    font-size: 1.65rem;
    font-weight: 700;
    margin-top: 10px;
}

.payment-end > span{
    color: #999999;
    font-size: 0.9rem;
}

.payment-end-img{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='146px' height='146px'%3E%3Cpath fill-rule='evenodd' stroke='rgb(34, 34, 34)' stroke-width='4px' stroke-linecap='butt' stroke-linejoin='miter' fill='rgb(255, 255, 255)' d='M72.000,2.000 C110.660,2.000 142.000,33.340 142.000,72.000 C142.000,110.660 110.660,142.000 72.000,142.000 C33.340,142.000 2.000,110.660 2.000,72.000 C2.000,33.340 33.340,2.000 72.000,2.000 Z'/%3E%3Cpath fill-rule='evenodd' fill='rgb(245, 56, 56)' d='M100.540,45.480 L61.000,85.020 L45.460,69.540 L37.000,78.000 L61.000,102.000 L109.000,54.000 L100.540,45.480 Z'/%3E%3C/svg%3E");
    background-size: 73px;
    background-repeat: no-repeat;
    width: 73px;
    height: 73px;
    display: inline-block;
}

.container.sub{
    position: fixed;
    overflow: hidden;
    height: 100vh;
    margin-bottom: 0;
}

.b-btn{
    text-align: center;
    position: fixed;
    bottom: 20px;
    width: 100%;
    line-height: 50px;
}


.b-btn a{
    color: #fff;
}


.form-floating {
    position: relative;
}


.form-floating label {
    position: absolute;
    cursor: text;
    font-size: 0.7rem;
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s;
    top: -.5em;
    left: 1.4rem;
    z-index: 3;
    line-height: 1;
    padding: 0 1px;
}

.form-floating label::after {
    content: " ";
    display: block;
    position: absolute;
    background: #fff;
    height: 2px;
    top: 50%;
    left: -.2em;
    right: -.2em;
    z-index: -1;
}

.form-floating .form-control:placeholder-shown:not(:focus)+* {
    font-size: 0.9rem;
    opacity: .5;
    top: .3em;
    z-index: -1;
}

.btn-group label:first-child{
    margin-right: 10px;
}

.input-bottom{
    border-right: none;
    border-top: none;
    border-radius: 0;
    border-left: none;
    border-bottom: 1px solid #222;
}

.search-icon{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='48px' height='48px'%3E%3Cpath fill-rule='evenodd' fill-opacity='0' fill='rgb(248, 248, 248)' d='M-0.000,48.000 L-0.000,-0.000 L48.000,-0.000 L48.000,48.000 L-0.000,48.000 Z'/%3E%3Cpath fill-rule='evenodd' fill='rgb(34, 34, 34)' d='M31.000,28.000 L29.420,28.000 L28.860,27.460 C30.820,25.180 32.000,22.220 32.000,19.000 C32.000,11.820 26.180,6.000 19.000,6.000 C11.820,6.000 6.000,11.820 6.000,19.000 C6.000,26.180 11.820,32.000 19.000,32.000 C22.220,32.000 25.180,30.820 27.460,28.860 L28.000,29.420 L28.000,31.000 L38.000,40.980 L40.980,38.000 L31.000,28.000 ZM19.000,28.000 C14.020,28.000 10.000,23.980 10.000,19.000 C10.000,14.020 14.020,10.000 19.000,10.000 C23.980,10.000 28.000,14.020 28.000,19.000 C28.000,23.980 23.980,28.000 19.000,28.000 Z'/%3E%3C/svg%3E");
    width: 24px;
    height: 24px;
    background-size: 24px;
    display: inline-block;
    border: none;
    background-color: transparent;
    position: absolute;
    right: 20px;
    top: 10px;
    background-repeat: no-repeat;
}

.address-form{
    margin-top: 1.0rem;
    height: 65vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.searchClear-wrap {
    position: absolute;
    z-index: 1;
    right: 65px;
    top: 3px;
    float: right;
    width: 18px;
    height: 18px;
    display: inline-block;
    border: none;
    background-color: transparent;
}

.searchClear {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='36px' height='36px'%3E%3Cpath fill-rule='evenodd' fill-opacity='0' fill='rgb(248, 248, 248)' d='M-0.000,36.000 L-0.000,-0.000 L36.000,-0.000 L36.000,36.000 L-0.000,36.000 Z'/%3E%3Cpath fill-rule='evenodd' fill='rgb(191, 191, 191)' d='M18.000,3.000 C9.705,3.000 3.000,9.705 3.000,18.000 C3.000,26.295 9.705,33.000 18.000,33.000 C26.295,33.000 33.000,26.295 33.000,18.000 C33.000,9.705 26.295,3.000 18.000,3.000 ZM25.500,23.385 L23.385,25.500 L18.000,20.115 L12.615,25.500 L10.500,23.385 L15.885,18.000 L10.500,12.615 L12.615,10.500 L18.000,15.885 L23.385,10.500 L25.500,12.615 L20.115,18.000 L25.500,23.385 Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    width: 18px;
    position: absolute;
    height: 18px;
    background-size: 18px;

}

.address-form-list ul li:first-child{
    border-top: none;
    padding-top: 0;
}

.address-form-list ul li {
    border-top: 1px solid #e6e6e6;
    padding-top: 20px;
}

.n-icon02{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24px' height='24px'%3E%3Cpath fill-rule='evenodd' fill='rgb(245, 56, 56)' d='M12.000,-0.000 C5.376,-0.000 -0.000,5.376 -0.000,12.000 C-0.000,18.624 5.376,24.000 12.000,24.000 C18.624,24.000 24.000,18.624 24.000,12.000 C24.000,5.376 18.624,-0.000 12.000,-0.000 ZM13.200,18.000 L10.800,18.000 L10.800,15.600 L13.200,15.600 L13.200,18.000 ZM13.200,13.200 L10.800,13.200 L10.800,6.000 L13.200,6.000 L13.200,13.200 Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
    background-size: 12px;
    display: inline-block;
    margin-right: 2px;
}


.bt-btn {
    text-align: center;
    position: absolute;
    bottom: 20px;
    width: 90%;
    line-height: 50px;
    background: #222;
    border-radius: 4px;
    border: none;
}

.line {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #4b9cdb;
}


.loadingBox .circle {
    position:fixed;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    width:40px;
    height:40px;
    border:6px solid #e6e6e6;
    border-top:6px solid #d6382c;
    border-radius:50em;
    animation-name:spinCircle;
    animation-duration:.8s;
    animation-iteration-count:infinite;
}

@keyframes spinCircle {
    from {
        transform:translate(-50%, -50%) rotate(0);
    }
    to {
        transform:translate(-50%, -50%) rotate(360deg);
    }
}

.loadingBox .circle {
    position:fixed;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    width:40px;
    height:40px;
    border:6px solid #e6e6e6;
    border-top:6px solid #d6382c;
    border-radius:50em;
    transition:all .2s;
    animation-name:spinCircle;
    animation-duration:.8s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}
